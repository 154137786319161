import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { campaignsSchema } from '../validations';

import Campaigns from './Campaigns.graphql';

type Campaigns = {
  account: {
    campaigns:
      | {
          name?: string | null;
          slug?: string | null;
          startDate?: string | null;
          expiryDate?: string | null;
        }[]
      | undefined;
  };
};

export const useCampaigns = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['campaigns'],
    query: Campaigns,
    select: (data: Campaigns) => data.account.campaigns,
    validateFn(response) {
      return campaignsSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber },
  });
};
