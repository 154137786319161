export enum PropertyType {
  Detached = 'DETACHED',
  Flat = 'FLAT',
  SemiDetached = 'SEMI_DETACHED',
  Terraced = 'TERRACED',
}

export const PROPERTY_TYPE_OPTIONS = [
  { label: 'Detached', value: PropertyType.Detached },
  { label: 'Flat', value: PropertyType.Flat },
  { label: 'Semi-detached', value: PropertyType.SemiDetached },
  { label: 'Terraced', value: PropertyType.Terraced },
];

export const MINIMUM_OCCUPANCY_NUMBER = 1;

export const MAX_OCCUPANCY_NUMBER = 10;

export enum StopTapAnswer {
  Yes = 'yes',
  No = 'no',
  Unknown = 'unknown',
}

export const APPLY_FOR_METER = 'APPLY_FOR_METER';
