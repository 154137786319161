import { array, object, string } from 'yup';

import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import DistrictMeteredArea from './DistrictMeteredArea.graphql';

const districtMeteredAreaTypeValidation = object({
  account: object({
    properties: array(
      object({
        externalPropertyReference: string().nullable(),
        propertyDetail: object({
          districtMeteredArea: string().nullable(),
        }).nullable(),
      }).required()
    ).required(),
  }).required(),
});

type districtMeteredAreaReturnType = {
  account: {
    properties: {
      externalPropertyReference?: string | null;
      propertyDetail?: {
        districtMeteredArea?: string | null;
      } | null;
    }[];
  };
};

export const useDistrictMeteredArea = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['districtMeteredArea'],
    query: DistrictMeteredArea,
    select: (data: districtMeteredAreaReturnType) => {
      return !!data.account.properties[0].propertyDetail?.districtMeteredArea;
    },
    validateFn(response) {
      return districtMeteredAreaTypeValidation.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber, activeFrom: new Date() },
  });
};
