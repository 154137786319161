import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import ActiveAssessedAgreement from '@/hooks/accounts/useHasActiveAssessedAgreement/HasActiveAssessedAgreement.graphql';
import { hasActiveAssessedAgreementSchema } from '@/hooks/accounts/validations';

type ActiveAssessedAgreement = {
  account: {
    hasActiveAssessedAgreement: boolean;
  };
};

export const useHasActiveAssessedAgreement = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['hasActiveAssessedAgreement'],
    query: ActiveAssessedAgreement,
    select: (data: ActiveAssessedAgreement) =>
      data.account.hasActiveAssessedAgreement,
    validateFn(response) {
      return hasActiveAssessedAgreementSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber },
  });
};
