import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { APPLY_FOR_METER } from '@/consts/applyForMeter';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import { meterFittingAppointmentSchema } from '@/hooks/usage/validations';

import MeterFittingAppointment from './MeterFittingAppointment.graphql';

type ServiceOrder = {
  node: {
    jobName?: string | null;
    scheduledAt?: string | null;
    scheduledEndAt?: string | null;
  };
};

type MeterFittingAppointmentResponse = {
  account: {
    properties: {
      serviceOrders?: {
        edges?: ServiceOrder[];
      };
    }[];
  };
};

export const useMeterFittingAppointment = () => {
  const { accountNumber } = useAccountNumberContext();

  const { isAuthenticated } = useAuth();

  const { data: activeWaterMeterCount } = useHasActiveWaterMeter();

  const isUnmetered = activeWaterMeterCount === 0;

  return useKrakenQuery({
    queryKey: ['meterFittingAppointment'],
    query: MeterFittingAppointment,
    validateFn(response) {
      return meterFittingAppointmentSchema.validate(response);
    },
    select: (data: MeterFittingAppointmentResponse) => {
      const serviceOrders =
        data.account.properties[0]?.serviceOrders?.edges || [];
      const meterFittingAppointment = serviceOrders.find(
        (serviceOrder: ServiceOrder) =>
          serviceOrder.node.jobName === APPLY_FOR_METER &&
          serviceOrder.node.scheduledAt &&
          serviceOrder.node.scheduledEndAt &&
          new Date(serviceOrder.node.scheduledAt) > new Date()
      );
      return meterFittingAppointment?.node;
    },
    enabled: Boolean(isAuthenticated && accountNumber && isUnmetered),
    variables: { accountNumber, activeFrom: new Date() },
    cacheTime: 0,
    refetchOnMount: 'always',
  });
};
