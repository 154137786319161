import { useCampaigns } from '@/hooks/accounts/useCampaigns';
import { useDistrictMeteredArea } from '@/hooks/accounts/useDistrictMeteredArea';
import { useHasActiveAssessedAgreement } from '@/hooks/accounts/useHasActiveAssessedAgreement';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import { useIsOnMeasuredTariff } from '@/hooks/usage/useIsOnMeasuredTariff';
import { useMeterFittingAppointment } from '@/hooks/usage/useMeterFittingAppointment';
import { currentDateIsBetweenStartAndEndDates } from '@/utils/date';

export const useEligibleForWaterMeterInstallation = () => {
  const {
    data: activeWaterMeterCount,
    isError: isErrorActiveWaterMeter,
    isLoading: isLoadingActiveWaterMeter,
  } = useHasActiveWaterMeter();

  const {
    data: hasAssessedTariff,
    isError: isErrorAssessedTariff,
    isLoading: isLoadingAssessedTariff,
  } = useHasActiveAssessedAgreement();

  const {
    data: campaigns,
    isError: isErrorCampaigns,
    isLoading: isLoadingCampaigns,
  } = useCampaigns();

  const {
    data: validDMA,
    isError: isErrorDMA,
    isLoading: isLoadingDMA,
  } = useDistrictMeteredArea();

  const isApplyForMeterCampaignActive = campaigns?.some((campaign) => {
    const { slug, startDate, expiryDate } = campaign;

    const campaignIsActive = currentDateIsBetweenStartAndEndDates({
      startDate,
      endDate: expiryDate,
    });

    return (
      campaignIsActive &&
      (slug === 'meter_install_not_possible' ||
        slug === 'meter_install_is_possible_customer_adaptions_required')
    );
  });

  const {
    data: meterFittingAppointment,
    isError: isErrorMeterFittingAppointment,
    isLoading: isLoadingMeterFittingAppointment,
  } = useMeterFittingAppointment();

  const {
    data: isMeasured,
    isLoading: isLoadingOnMeasuredTariff,
    isError: isErrorOnMeasuredTariff,
  } = useIsOnMeasuredTariff();

  const isMetered = !!activeWaterMeterCount && activeWaterMeterCount > 0;

  const hasCheckMeter =
    isMetered &&
    !isMeasured &&
    !isLoadingOnMeasuredTariff &&
    !isErrorOnMeasuredTariff;

  const hasNoDMA = !validDMA && !isLoadingDMA && !isErrorDMA;

  // Determine overall loading as a combination of all loading states
  const isLoading =
    isLoadingActiveWaterMeter ||
    isLoadingAssessedTariff ||
    isLoadingMeterFittingAppointment ||
    isLoadingCampaigns ||
    isLoadingOnMeasuredTariff ||
    isLoadingDMA;

  // Determine overall error as a combination of all error states
  const isError =
    isErrorActiveWaterMeter ||
    isErrorAssessedTariff ||
    isErrorMeterFittingAppointment ||
    isErrorCampaigns ||
    isErrorOnMeasuredTariff ||
    isErrorDMA;

  // Ensure eligibility is only computed when loading is false.
  const isEligible =
    !isLoading &&
    !isMetered &&
    !isError &&
    !hasAssessedTariff &&
    !meterFittingAppointment &&
    !isApplyForMeterCampaignActive &&
    validDMA;

  return {
    // Additionally check if the account hasCheckMeter because we want to render the action card but then go to the info page.
    data: isEligible || hasCheckMeter || hasNoDMA,
    isLoading,
    isError,
    hasCheckMeter,
    hasNoDMA: hasNoDMA,
  };
};
